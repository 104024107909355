<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems" />
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div class="table-responsive roles-table">
      <vue-good-table
        styleClass="vgt-table striped"
        :columns="columns"
        :fixed-header="false"
        :rows="roles"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
          trigger: 'enter',
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-per-page-change="onPerPageChange"
      >
        <template slot="table-row" slot-scope="props">
          <span
            class="d-flex align-items-center gap-2"
            v-if="props.column.field === 'action'"
          >
            <div
              v-if="$can(`${$route.meta.permission}.edit`)"
              class="cursor-pointer"
              @click="$router.push(`/roles/${props.row.id}/edit`)"
            >
              <feather-icon size="16" icon="Edit2Icon" />
            </div>
            <div
              v-if="$can(`${$route.meta.permission}.delete`)"
              class="cursor-pointer"
              @click="destroy(props.row.id)"
            >
              <feather-icon size="16" icon="TrashIcon" />
            </div>
          </span>
          <span
            v-else-if="props.column.field == 'scope'"
            class="custom-pointer position-relative"
          >
            <button
              :id="'permission-button-' + props.row.id"
              @click="showPermissions(props.row.id)"
              :class="[
                'btn-sm',
                'btn',
                'btn-primary',
                'rounded',
                props.row.id == showId ? 'docsHeroLogo' : 'docsHeroColorBlue',
              ]"
            >
              {{ props.row.id == showId ? "Hide" : "Show" }}

              {{ $t("Permissions") }}
            </button>

            <div
              class="custom-modal role-permission-dropdown"
              v-if="props.row.id == showId"
            >
              <div class="modal-overlay"></div>
              <div class="modal-content">
                <div class="modal-md modal-bg">
                  <div class="modal-header">
                    <div class="d-flex align-items-center">
                      <h3>{{ $t("Permissions") }}</h3>
                    </div>
                    <div class="x-icon" @click="closePermissions">
                      <feather-icon size="16" icon="XIcon" />
                    </div>
                  </div>
                  <div class="modal-body">
                    <treeselect
                      :ref="`treeselect-${props.row.id}`"
                      :clearable="false"
                      v-model="props.row.permissions"
                      :multiple="true"
                      :options="permissionsTree"
                      :placeholder="$t('Search')"
                      :alwaysOpen="true"
                      openDirection="above"
                      :valueConsistsOf="'LEAF_PRIORITY'"
                    >
                      <template #value-label="{ node }">
                        <!-- Render nothing to hide selected values -->
                      </template>
                    </treeselect>
                  </div>
                  <div
                    class="modal-footer d-flex align-items-center justify-content-end"
                  >
                    <button class="btn btn-primary" @click="closePermissions">
                      <ElementIcon v-bind:icon="'cancelIcon'" />
                      {{ $t("Cancel") }}
                    </button>
                    <button
                      class="btn btn-secondary"
                      @click="syncPermissions(props.row)"
                    >
                      <ElementIcon v-bind:icon="'saveIcon'" />
                      {{ $t("Save") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-end flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="count"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => onPageChange({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ElementIcon from "@/components/elements/Icon.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    PageHeader,
    Treeselect,
    ElementIcon,
  },
  computed: {
    ...mapGetters(["showLoader"]),
    ...mapGetters("roles", ["roles", "count"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Roles"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("Title"),
          field: "title",
          sortable: false,
        },
        {
          label: this.$t("Scope"),
          field: "scope",
          sortable: false,
        },
        {
          label: this.$t("Action"),
          field: "action",
          sortable: false, // disable sorting on this column
        },
      ];
    },
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create Role"),
          icon: "PlusIcon",
          path: "/roles/create",
          permission: "role.create",
        },
      };
    },
  },
  data() {
    return {
      showId: 0,
      totalRecords: 0,
      pageLength: 50,
      page: 1,
      permissionsTree: [],
      permissions: [],
      rows: [],
      searchTerm: "",
      isPermissionFetching: false,
    };
  },

  async created() {
    this.$store.commit("showLoader", true);
    this.$store
      .dispatch("permissions/list", {
        limit_start: 0,
        limit_count: 1500,
      })
      .then((res) => {
        this.permissions = res?.data?.data ?? [];
      })
      .finally(() => {
        if (!this.permissionsTree?.length) {
          this.createBasePermissionGroups();
          this.createSectionedGroups();
        }
        this.$store.commit("showLoader", false);
      });
    this.loadItems();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    // async showPermissions(id) {
    //   const treeselectDom = this.$refs[`treeselect-${id}`]?.$el;
    //   treeselectDom.style.left = "5rem";
    //   treeselectDom.querySelector(".vue-treeselect__input").placeholder =
    //     "Search";
    //   if (this.showId == id) {
    //     this.showId = 0;
    //   } else {
    //     this.showId = id;
    //   }
    // },
    async showPermissions(roleId) {
      if (this.showId == roleId) {
        this.showId = 0;
      } else {
        this.showId = roleId;
      }
    },
    closePermissions() {
      this.showId = 0;
    },
    async syncPermissions(role) {
      this.showId = 0;
      await this.$nextTick();
      try {
        this.$store.commit("isLoading", true);
        await this.$store.dispatch("roles/update", {
          ...role,
          permissions: role.permissions,
          can_register: role.can_register === true ? 1 : 0,
          active: role.active === true ? 1 : 0,
        });
      } catch (e) {}
    },
    onSortChange(params) {
      this.sortType = params[0].type;
      this.sortField = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      let response = await this.$store.dispatch("roles/list", {
        limit_start: this.pageLength * (this.page - 1),
        limit_count: this.pageLength,
        search_string: this.searchTerm,
      });
    },
    /**
     * the permissions that we get from the backend are in the form like this:
     * {active: 1
     *  can_be_assigned: 1
     *  description: "this is a description"
     *  grouping: "settings/administration"
     *  id: 1
     *  needs_permission: null
     *  scope: "auth_backend"
     *  title: "permission.list"
     *  value: 1}
     * this function creates 'permission' as a group and pushes the 'list' in it's children array
     * if we encounter a permission with the title 'permssion.create' we grab the already created 'permission' group
     * and push 'create' in it's children array
     * @returns {permissionsTree} the created groups tree
     */
    createBasePermissionGroups() {
      const scopes = {};
      this.permissions.forEach((permission) => {
        if (typeof permission?.title === "string") {
          if (!scopes[permission.scope]) {
            scopes[permission.scope] = {};
          }
          if (!permission.grouping) {
            scopes[permission.scope][permission.title] = permission;
          } else {
            if (!scopes[permission.scope][permission.grouping])
              scopes[permission.scope][permission.grouping] = {};
            const permissionsTree =
              scopes[permission.scope][permission.grouping];
            const permissionKey = permission.title.split(".")?.[0];
            const permissionName = permission.title.split(".")?.[1];
            if (scopes[permission.scope][permission.grouping][permissionKey]) {
              const permissionNode = permissionsTree[permissionKey];
              permissionNode["children"].push({
                id: permission.id,
                label: permissionName,
                grouping: permission.grouping,
                description: permission.description,
                scope: permission.scope,
              });
            } else {
              permissionsTree[permissionKey] = {
                id: uuidv4(),
                label: permission.title.split(".")?.[0],
                grouping: permission.grouping,
                description: permission.description,
                scope: permission.scope,
              };
              if (permission.title.split(".")?.[0] !== permission.title) {
                permissionsTree[permissionKey]["children"] = [
                  {
                    id: permission.id,
                    label: permissionName,
                    grouping: permission.grouping,
                    description: permission.description,
                    scope: permission.scope,
                  },
                ];
              } else {
                permissionsTree[permissionKey]["id"] = permission.id;
              }
            }
            scopes[permission.scope][permission.grouping] = permissionsTree;
          }
        }
      });
      this.scopes = scopes;
    },
    /**
     * this.groups has the groups in the form like {'sidebar/master data': {all the groups related to this permission group}}
     * this function splits the key('sidebar/master data') by '/' and creates a parent(sidebar) and a child(master data) group and
     * puts all the relevant permissions groups in their respective parent/child groups
     */
    createSectionedGroups() {
      let permissionsTree = [];
      for (let scope in this.scopes) {
        let sectionedGroups = [];
        for (let group in this.scopes[scope]) {
          let keys = group.split("/");
          let parent = null;
          keys.forEach((key, index) => {
            if (index === 0) {
              let foundGroup = sectionedGroups.find(
                (improvedGroup) => improvedGroup.label === key
              );
              if (!foundGroup) {
                if (this.scopes[scope][group]) {
                  sectionedGroups.push({
                    id: uuidv4(),
                    label: key,
                    children: [],
                  });
                } else {
                  sectionedGroups.push(this.scopes[scope][group]);
                }
              }
              parent = sectionedGroups.find(
                (improvedGroup) => improvedGroup.label === key
              );
            } else {
              let foundGroup = parent.children.find(
                (child) => child.label === key
              );
              if (!foundGroup) {
                parent.children.push({
                  id: uuidv4(),
                  label: key,
                  children: [],
                });
              }
              parent = parent.children.find((child) => child.label === key);
            }
          });
          if (this.scopes[scope][group] && !this.scopes[scope][group]?.id) {
            parent.children = [
              ...parent.children,
              ...Object.values(this.scopes[scope][group]),
            ];
          } else {
            parent.children = [...parent.children, this.scopes[scope][group]];
          }
        }
        permissionsTree.push({
          id: uuidv4(),
          label: scope,
          children: [...sectionedGroups],
        });
      }
      this.permissionsTree = [...permissionsTree];
    },
    async destroy(id) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store.dispatch("roles/destroy", {
            id: id,
          });
          this.loadItems();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.role-permission-dropdown {
  // position: absolute;
  // bottom: 30px;
  // left: 0 !important;
  // width: 550px;
  // z-index: 999;
  // .vue-treeselect__list-item {
  //   .vue-treeselect__list {
  //     padding-left: 15px;
  //   }
  // }
  // .vue-treeselect__menu-container{
  //   position: relative;
  //   .vue-treeselect__menu{
  //     position: relative;
  //   }
  // }
  // .modal-bg{
  //   max-height: calc(100vh - 100px);
  // }
  .vue-treeselect {
    .vue-treeselect__control {
      border: 1px solid #38414a;
      border-radius: 6px !important;
      margin-bottom: 10px;
      padding: 0 10px;
      display: flex;
      .vue-treeselect__value-container {
        height: 100%;
        .vue-treeselect__multi-value {
          display: flex;
          flex-wrap: wrap;
          margin: 0;
          height: 100%;
          .vue-treeselect__multi-value-item-container {
            display: none !important;
          }
          .vue-treeselect__placeholder {
            display: block;
            width: 100%;
            font-size: 16px;
            line-height: 40px;
          }
          .vue-treeselect__input-container {
            padding: 0;
            height: 100%;
            .vue-treeselect__input {
              padding: 0;
            }
          }
        }
      }
    }
    .vue-treeselect__menu-container {
      position: relative;
      .vue-treeselect__menu {
        position: relative;
        // overflow: visible;
        max-height: calc(78vh - 100px) !important;
        height: 100%;
        border: 1px solid #38414a;
        border-radius: 6px !important;
        .vue-treeselect__list .vue-treeselect__list-item {
          padding-left: 15px;
        }
        .vue-treeselect__list .vue-treeselect__indent-level-0 {
          padding-left: 0;
        }
      }
    }
  }
  .vue-treeselect--focused {
    .vue-treeselect__control {
      .vue-treeselect__value-container {
        .vue-treeselect__multi-value {
          .vue-treeselect__placeholder {
            display: none;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.hidden {
  display: none;
}

:deep(.vue-treeselect__control) {
  opacity: 1;
}
:deep(.vue-treeselect__input) {
  width: auto !important;
  margin-left: 0.5rem;
}
/* vue-treeselect__multi-value */
:deep(.vue-treeselect__multi-value-item-container) {
  display: none !important;
}
:deep(.vue-treeselect__placeholder) {
  display: none !important;
}
:deep(.vue-treeselect__menu) {
  max-height: 200px !important;
}
:deep(.vue-treeselect__label) {
  text-transform: capitalize;
}
:deep(.vue-treeselect__option) {
  display: flex;
}
:deep(.vue-treeselect__option-arrow-container) {
  align-self: center;
}
:deep(.vue-treeselect__label-container) {
  margin: 10px 0px;
}
:deep(.vue-treeselect__label-container p) {
  margin: 0px 0px 0px 5px;
}
</style>
